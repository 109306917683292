import React from 'react';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import { useFormikContext } from 'formik';
import Divider from '@setproduct-ui/core/Divider';
import PriceCalculator from 'app/helpers/priceCalculator';
import CouponCodeInput from '../CouponCodeInput';
import DiscountCouponsList from '../DiscountCouponsList';
import { AvailableProducts, LabKitTypes } from 'app/constants/Products';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntakeProduct } from 'app/selectors/customer';
import { useExperiment } from 'app/utils/useExperiment';

type Props = {
  multimonthPeriod: string;
  multimonthPlan: number;
  starterPack: boolean;
  isOnboarding: boolean;
  showLabKit: boolean;
  priceCalculator: PriceCalculator;
  intake: any;
};

const GenericDueToday = ({
  isOnboarding,
  priceCalculator,
  multimonthPeriod,
  multimonthPlan,
  starterPack,
  showLabKit,
  intake,
}: Props) => {
  const { values } = useFormikContext<{
    use_own_lab: boolean;
    lab_kit_type: string;
  }>();
  const { use_own_lab, lab_kit_type } = values;

  const currentProductName = useAppSelector(selectCurrentIntakeProduct);

  const labsFreeExperiment =
    useExperiment('testosterone_discount_labs_free', 'testosterone_discount_labs_free_variation_0') ===
    'testosterone_discount_labs_free_variation_1';

  const firstInstallmentDiscount =
    useExperiment('testosterone_first_installment_discount', 'testosterone_first_installment_discount_variation_0') ===
      'testosterone_first_installment_discount_variation_1' &&
    isOnboarding &&
    currentProductName === AvailableProducts.King;

  const isLabsFree = isOnboarding && currentProductName === 'king' && labsFreeExperiment;

  return (
    <div className="flex flex-col gap-y-2">
      {isOnboarding && firstInstallmentDiscount && (
        <div className="flex flex-row">
          <div className="flex-1">Plan {multimonthPlan !== 1 && <span>(first month discount)</span>}</div>
          <div>
            {priceCalculator.monthlyInstallmentCost() > 0 ? (
              <PriceFormatter size="small" price={priceCalculator.monthlyInstallmentCost()} />
            ) : (
              'Free'
            )}
          </div>
        </div>
      )}
      {firstInstallmentDiscount && multimonthPlan > 0 && multimonthPlan !== 1 && (
        <div className="flex flex-row">
          ${Math.floor(priceCalculator.fullMonthlyCost() / 100)}
          /mo after the first month
        </div>
      )}
      {isOnboarding && !firstInstallmentDiscount && multimonthPlan > 0 && !starterPack && (
        <div className="flex flex-row">
          <div className="flex-1">
            Subscription <span>({multimonthPeriod.toLowerCase()})</span>
          </div>
          <div>
            <PriceFormatter size="small" price={priceCalculator.subscriptionCost()} />
          </div>
        </div>
      )}
      {isOnboarding && starterPack && (
        <div className="flex flex-row">
          <div className="flex-1">
            Starter pack <span>({multimonthPlan} bottles)</span>
          </div>
          <div>
            <PriceFormatter size="small" price={priceCalculator.monthlyCost()} />
          </div>
        </div>
      )}
      {isOnboarding && starterPack && priceCalculator.bbIncluded() && (
        <div className="flex flex-row">
          <div className="flex-1">
            Building Blocks <span>({multimonthPlan} bottles)</span>
          </div>
          <div>
            <PriceFormatter size="small" price={priceCalculator.bbPrice() * multimonthPlan} />
          </div>
        </div>
      )}
      {!priceCalculator.freeShipping() && (
        <div className="flex flex-row">
          <div className="flex-1">Priority 2 day shipping</div>
          <div>
            <PriceFormatter size="small" price={priceCalculator.shippingPrice()} />
          </div>
        </div>
      )}
      {!use_own_lab && showLabKit && (
        <div className="flex flex-row">
          <div className="flex-1">{lab_kit_type === LabKitTypes.WalkIn ? 'Walk-in Lab' : 'At-Home Lab Kit:'}</div>
          {isLabsFree && lab_kit_type === LabKitTypes.AtHomeLabKit ? (
            <PriceFormatter size="small" price={priceCalculator.atHomeLabPrice() as number} free={isLabsFree} />
          ) : (
            <PriceFormatter size="small" price={priceCalculator.labCost()} />
          )}
        </div>
      )}
      {priceCalculator.appointmentCost() > 0 && (
        <div className="flex flex-row">
          <div className="flex-1">Video Consultation</div>
          <div>
            <PriceFormatter size="small" price={priceCalculator.appointmentCost()} showCents={true} />
          </div>
        </div>
      )}
      <div className="flex flex-row">
        <div className="flex-1">Priority Shipping (to you)</div>
        <div>
          {priceCalculator.shippingPrice() > 0 ? (
            <PriceFormatter size="small" price={priceCalculator.shippingPrice()} />
          ) : (
            'Free'
          )}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex-1">Overnight Shipping (to the lab)</div>
        <div>Free</div>
      </div>
      <DiscountCouponsList priceCalculator={priceCalculator} />

      <Divider className="my-3" />

      <div className="flex flex-row items-center">
        <h5 className="flex-1">TOTAL:</h5>
        {isOnboarding && firstInstallmentDiscount && priceCalculator.totalDueToday() <= 0 ? (
          'Free'
        ) : (
          <PriceFormatter price={priceCalculator.totalDueToday()} className="font-medium" showCents={true} />
        )}
      </div>
      <div className="discount-coupons-container">
        <div className="price_row">
          <CouponCodeInput productName={intake?.get('product_name')} />
        </div>
      </div>
    </div>
  );
};

export default GenericDueToday;
