/* eslint-disable no-case-declarations */
import { LabKitTypes, KingV2SubProducts } from 'app/constants/Products';
import BasePriceCalculator from 'app/helpers/productSpecificPriceCalculators/basePriceCalculator';
import { ImmutableList } from 'app/types/admin';
import { IExperiment } from 'app/types/admin/customerUser';
import { List } from 'immutable';

const BB_PRICING = {
  1: 49_99,
  3: 44_99,
  12: 34_99,
};

export const KING_MONTHLY_PRICE = {
  [KingV2SubProducts.Enclo]: {
    1: 199_99,
    3: 149_99,
    12: 99_99,
  },
  [KingV2SubProducts.EncloPregnolone]: {
    1: 199_99,
    3: 149_99,
    12: 99_99,
  },
  [KingV2SubProducts.OralTrt]: {
    1: 249_99,
    3: 199_99,
    12: 149_99,
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    1: 299_99,
    3: 249_99,
    12: 199_99,
  },
  [KingV2SubProducts.EncloTrt]: {
    1: 299_99,
    3: 249_99,
    12: 199_99,
  },
  [KingV2SubProducts.Topical]: {
    1: 209_99,
    3: 159_99,
    12: 109_99,
  },
  [KingV2SubProducts.EncloTopical]: {
    1: 289_99,
    3: 239_99,
    12: 189_99,
  },
  [KingV2SubProducts.Injectable]: {
    1: 199_99,
    3: 149_99,
    12: 99_99,
  },
  [KingV2SubProducts.EncloInjectable]: {
    1: 279_99,
    3: 229_99,
    12: 179_99,
  },
  [KingV2SubProducts.InjectableHCG]: {
    1: 399_99,
    3: 349_99,
    12: 299_99,
  },
};

const LAB_PRICE_BY_INTAKE_NUMBER = {
  1: 149_99,
  2: 99_99,
  3: 149_99,
};

// Add new experiment pricing constants
const KING_EXPERIMENT_PRICING_V1 = {
  [KingV2SubProducts.Enclo]: {
    1: 149_99,
    3: 124_99,
    12: 99_99,
  },
  [KingV2SubProducts.EncloPregnolone]: {
    1: 149_99,
    3: 124_99,
    12: 99_99,
  },
  [KingV2SubProducts.OralTrt]: {
    1: 199_99,
    3: 174_99,
    12: 149_99,
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    1: 299_99,
    3: 249_99,
    12: 199_99,
  },
  [KingV2SubProducts.EncloTrt]: {
    1: 249_99,
    3: 224_99,
    12: 199_99,
  },
  [KingV2SubProducts.Topical]: {
    1: 159_99,
    3: 134_99,
    12: 109_99,
  },
  [KingV2SubProducts.EncloTopical]: {
    1: 239_99,
    3: 214_99,
    12: 189_99,
  },
  [KingV2SubProducts.Injectable]: {
    1: 149_99,
    3: 124_99,
    12: 99_99,
  },
  [KingV2SubProducts.EncloInjectable]: {
    1: 279_99,
    3: 229_99,
    12: 179_99,
  },
  [KingV2SubProducts.InjectableHCG]: {
    3: 349_99,
    12: 299_99,
  },
};

const KING_EXPERIMENT_PRICING_V2 = {
  [KingV2SubProducts.Enclo]: {
    1: 149_99,
    3: 119_99,
    12: 79_99,
  },
  [KingV2SubProducts.EncloPregnolone]: {
    1: 149_99,
    3: 119_99,
    12: 79_99,
  },
  [KingV2SubProducts.OralTrt]: {
    1: 199_99,
    3: 169_99,
    12: 129_99,
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    1: 299_99,
    3: 249_99,
    12: 199_99,
  },
  [KingV2SubProducts.EncloTrt]: {
    1: 249_99,
    3: 219_99,
    12: 179_99,
  },
  [KingV2SubProducts.Topical]: {
    1: 159_99,
    3: 129_99,
    12: 89_99,
  },
  [KingV2SubProducts.EncloTopical]: {
    1: 239_99,
    3: 209_99,
    12: 169_99,
  },
  [KingV2SubProducts.Injectable]: {
    1: 149_99,
    3: 119_99,
    12: 79_99,
  },
  [KingV2SubProducts.EncloInjectable]: {
    1: 279_99,
    3: 229_99,
    12: 179_99,
  },
  [KingV2SubProducts.InjectableHCG]: {
    3: 349_99,
    12: 299_99,
  },
};

const LAB_PRICE_WITH_LAAS_ORDERED = {
  1: 69_99,
  2: 99_99,
  3: 149_99,
};

export const kingMonthlyPriceExperiment = (experimentVariation?: string) => {
  if (experimentVariation === 'testosterone_pricing_variation_1') {
    return KING_EXPERIMENT_PRICING_V1;
  } else if (experimentVariation === 'testosterone_pricing_variation_2') {
    return KING_EXPERIMENT_PRICING_V2;
  }
  return KING_MONTHLY_PRICE;
};

class KingPriceCalculator extends BasePriceCalculator {
  selected_king_v2_product: KingV2SubProducts;
  isOnboarding: boolean;
  oralTrtLabNumber?: 1 | 2 | 3 | null;
  crlFeatureEnabledEncloPregWeightLoss: boolean;
  labKitType: LabKitTypes;
  productChangedtoOralTrt: boolean;
  experiments: ImmutableList<IExperiment>;
  only_lab: boolean;

  KING_LAB_COST = 99_99;
  KING_WALK_IN_LAB_COST = 109_99;
  LEGACY_KING_LAB_COST = 99_99;
  APPOINTMENT_PRICE = 20_00;
  SHIPPING_PRICE = 12_00;

  constructor(
    includeBB: boolean,
    includeBBonAnotherProtocol: boolean,
    selected_king_v2_product: KingV2SubProducts,
    multiMonthPlan: 1 | 3 | 12,
    useOwnLab: boolean,
    intakeName: string,
    oral_trt_lab_number: null | 1 | 2 | 3 = null,
    crlFeatureEnabledEncloPregWeightLoss = false,
    labKitType: LabKitTypes = LabKitTypes.AtHomeLabKit,
    isOnboarding = false,
    subscriptionPaid = false,
    labPaid = false,
    productChangedtoOralTrt = false,
    experiments: ImmutableList<IExperiment> = List() as ImmutableList<IExperiment>,
    only_lab = false,
    monthly_installment_plan = false,
  ) {
    super(
      includeBB,
      includeBBonAnotherProtocol,
      intakeName,
      multiMonthPlan,
      useOwnLab,
      subscriptionPaid,
      labPaid,
      monthly_installment_plan,
    );

    // This is specifically for when an Admin updates the User's
    //  Enclomiphene Version to Enclo Only before they check out
    if (selected_king_v2_product === KingV2SubProducts.Enclo) {
      this.selected_king_v2_product = KingV2SubProducts.EncloPregnolone;
    } else {
      this.selected_king_v2_product = selected_king_v2_product;
    }

    this.labKitType = labKitType;
    this.oralTrtLabNumber = oral_trt_lab_number;
    this.crlFeatureEnabledEncloPregWeightLoss = crlFeatureEnabledEncloPregWeightLoss;
    this.isOnboarding = isOnboarding;
    this.productChangedtoOralTrt = productChangedtoOralTrt;
    this.experiments = experiments;
    this.only_lab = only_lab;
  }

  getExperimentVariation = (key: string) => {
    const currentExperiment = this.experiments?.find((e) => e.get('experiment_id') === key);
    return currentExperiment && currentExperiment.get('variation_value');
  };

  isFirstInstallmentDiscount = () => {
    return (
      this.monthly_installment_plan &&
      this.getExperimentVariation('testosterone_first_installment_discount') ===
        'testosterone_first_installment_discount_variation_1'
    );
  };

  // Interface
  monthlyCost() {
    if (this.isFirstInstallmentDiscount()) {
      return KING_MONTHLY_PRICE[this.selected_king_v2_product][12];
    }

    return kingMonthlyPriceExperiment(this.getExperimentVariation('testosterone_pricing'))[
      this.selected_king_v2_product
    ][this.multiMonthPlan];
  }

  fullMonthlyCost() {
    return this.monthlyCost()! + (this.includeBB ? this.bbPrice() : 0);
  }

  subscriptionCost() {
    if (this.subscriptionPaid) return 0;

    if (!this.multiMonthPlan) {
      return 199_00;
    }

    const bbMonthlyPrice = this.includeBB ? BB_PRICING[this.multiMonthPlan] : 0;

    return (this.monthlyCost() + bbMonthlyPrice) * (this.monthly_installment_plan ? 1 : this.multiMonthPlan);
  }

  appointmentCost() {
    return this.oralTrt() && (this.isOnboarding || this.productChangedtoOralTrt) ? this.APPOINTMENT_PRICE : 0;
  }

  atHomeLabPrice() {
    if (this.oralTrt()) {
      const monthNumber = this.oralTrtLabNumber || 1;
      if (this.only_lab) {
        return LAB_PRICE_WITH_LAAS_ORDERED[monthNumber];
      } else {
        return LAB_PRICE_BY_INTAKE_NUMBER[monthNumber];
      }
    } else if (this.crlFeatureEnabledEncloPregWeightLoss) {
      return this.KING_LAB_COST;
    } else {
      return this.LEGACY_KING_LAB_COST;
    }
  }

  labCost() {
    if (this.labPaid || this.useOwnLab || (!this.isOnboarding && !this.productChangedtoOralTrt)) return 0;

    return this.labKitType === LabKitTypes.WalkIn ? this.KING_WALK_IN_LAB_COST : this.atHomeLabPrice();
  }

  totalDueTodayForCurrentProduct = () =>
    this.subscriptionCost() + this.labCost() + this.appointmentCost() + this.shippingPrice();

  // Discounts
  bbDiscount() {
    if (!(this.includeBB || this.includeBBonAnotherProtocol) || !this.multiMonthPlan || this.multiMonthPlan < 3) {
      return 0;
    }

    return this.bbPotentialDiscount();
  }

  bbPotentialDiscount = () => (this.BB_PRICE - this.bbPrice() > 0 ? this.BB_PRICE : 0);

  nonDiscountedPrice = () => 0;

  // Private product specific methods
  oralTrt() {
    const trtProducts = [
      KingV2SubProducts.OralTrt,
      KingV2SubProducts.EncloPregnoloneTrt,
      KingV2SubProducts.EncloTrt,
      KingV2SubProducts.Injectable,
      KingV2SubProducts.EncloInjectable,
      KingV2SubProducts.InjectableHCG,
      KingV2SubProducts.Topical,
      KingV2SubProducts.EncloTopical,
    ];

    return trtProducts.includes(this.selected_king_v2_product);
  }

  bbPrice = () => {
    return BB_PRICING[this.multiMonthPlan];
  };

  montlyPayment = () => {
    return kingMonthlyPriceExperiment(this.getExperimentVariation('testosterone_pricing'))[
      this.selected_king_v2_product
    ][1];
  };

  quarterlyPayment = () => {
    return (
      kingMonthlyPriceExperiment(this.getExperimentVariation('testosterone_pricing'))[
        this.selected_king_v2_product
      ][3] * 3
    );
  };

  yearlyPayment = () => {
    return (
      kingMonthlyPriceExperiment(this.getExperimentVariation('testosterone_pricing'))[
        this.selected_king_v2_product
      ][12] * 12
    );
  };

  shippingPrice() {
    if (this.isFirstInstallmentDiscount() && this.multiMonthPlan === 1) {
      return this.SHIPPING_PRICE;
    }

    return 0;
  }
}

export default KingPriceCalculator;
