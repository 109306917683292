import React, { FC, useEffect } from 'react';
import 'app/components/customer/steps/Payment/css/MultimonthPlanSelection.scss';
import { useFormikContext } from 'formik';
import { Badge, Card, PlansList } from 'mui';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import subProductNames from 'app/utils/subProductNames';
import { AvailableProducts } from 'app/constants/Products';
import { useExperiment } from 'app/utils/useExperiment';
import cx from 'classnames';

interface GenericMultimonthPlanSelectionFormProps {
  multimonthConfig: any[];
  priceConfig: Record<string, Record<number, number>>;
  productName: string;
  selectedSubProduct: string;
  isOnboarding?: boolean;
}

const GenericMultimonthPlanSelectionForm: FC<GenericMultimonthPlanSelectionFormProps> = ({
  multimonthConfig,
  priceConfig,
  productName,
  selectedSubProduct,
  isOnboarding = false,
}) => {
  const monthlyInstallmentPlan =
    useExperiment('monthly_installment_plan', 'monthly_installment_plan_variation_0') ===
      'monthly_installment_plan_variation_1' && productName === AvailableProducts.King;
  const firstInstallmentDiscount =
    useExperiment('testosterone_first_installment_discount', 'testosterone_first_installment_discount_variation_0') ===
      'testosterone_first_installment_discount_variation_1' && productName === AvailableProducts.King;

  const { values, setFieldValue } = useFormikContext<{
    multimonth_plan: number;
    starter_pack: boolean;
  }>();

  const currentPlanValue = values.multimonth_plan;

  useEffect(() => {
    if (currentPlanValue && !priceConfig[selectedSubProduct][currentPlanValue]) {
      setFieldValue('multimonth_plan', Object.keys(priceConfig[selectedSubProduct])[0]);
    }
  });

  const renderPlanItemPrice = (config) => {
    if (!(firstInstallmentDiscount && isOnboarding)) return;

    const totalPrice = priceConfig[selectedSubProduct][12];

    if (config.value === 3) {
      return (
        <>
          <sup>$</sup>
          {parseInt(String((totalPrice * 0.5) / 100))}
          <sup>{(((totalPrice * 0.5) / 100) % 1).toFixed(2).replace('0.', '').replace('1.00', '99')}</sup>
          <br />
          <span className="text-xs font-normal leading-4 text-right">first month</span>
        </>
      );
    }

    if (config.value === 1) {
      return (
        <>
          <sup>$</sup>
          {parseInt(String(totalPrice / 100))}
          <sup>{((totalPrice / 100) % 1).toFixed(2).replace('0.', '')}</sup>
          <br />
          <span className="text-xs font-normal leading-4 text-right">each month</span>
        </>
      );
    }

    return (
      <>
        Free
        <br />
        <span className="text-xs font-normal leading-4 text-right">first month</span>
      </>
    );
  };

  const renderPlanItemText = (config) => {
    if (!(firstInstallmentDiscount && isOnboarding)) return null;

    const totalPrice = priceConfig[selectedSubProduct][12];

    if (config.value === 1) {
      return <>${totalPrice / 100}/mo + shipping</>;
    }

    return <>${totalPrice / 100}/mo after – free shipping</>;
  };

  const renderPlan = (config) => {
    let val = config.starter_pack ? 'starter_pack' : config.value;
    if (firstInstallmentDiscount) val = 12;
    const totalPrice = priceConfig[selectedSubProduct][val];

    if (!totalPrice) return null;

    const priceUnitDenom = config.starter_pack ? 300 : 100;
    return {
      title: config.title,
      badgeContent: config.badgeContent,
      titleExtra: config.titleExtra,
      checked: config.value === values.multimonth_plan && config.starter_pack == values.starter_pack,
      priceUnit: parseInt(String(priceConfig[selectedSubProduct][val] / priceUnitDenom)),
      priceFraction: ((priceConfig[selectedSubProduct][val] / priceUnitDenom) % 1).toFixed(2).replace('0.', ''),
      text: (
        <div>
          {firstInstallmentDiscount && isOnboarding
            ? renderPlanItemText(config)
            : monthlyInstallmentPlan || firstInstallmentDiscount
              ? 'Bills monthly'
              : config.subheader}
        </div>
      ),
      testid: `select-plan-${val}`,
      value: config.value,
      planItemPrice: renderPlanItemPrice(config),
      onChange: () => {
        setFieldValue('multimonth_plan', config.value);
        setFieldValue('starter_pack', config.starter_pack);
      },
    };
  };

  return (
    <Card className={cx({ 'first-installment-discount': firstInstallmentDiscount })}>
      <Card.Body>
        <figure className="h-[180px] lg:h-[240px] mb-4">
          <img
            className="h-full w-auto"
            src={
              productName == AvailableProducts.Lover
                ? ImagesMapping[productName]
                : ImagesMapping[productName][selectedSubProduct]
            }
            alt={productName}
          />
        </figure>
        <div className="text-center">
          <Badge variant={productName} className="mb-2">
            {protocolNames[productName]}
          </Badge>
          {productName == AvailableProducts.Lover && <h4>Vardenafil + Tadalafil</h4>}
          <h4>{subProductNames[productName][selectedSubProduct]}</h4>
        </div>
        <PlansList
          items={multimonthConfig.map((config) => renderPlan(config)).filter((x) => x !== null)}
          radioName="multimonth_plan"
        />
      </Card.Body>
    </Card>
  );
};

export default GenericMultimonthPlanSelectionForm;
