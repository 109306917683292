import React, { useContext } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { selectManagedProduct, selectCurrentIntakeProduct } from 'app/selectors/customer';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import './css/DiscountCouponsList.scss';
import { ProductContext } from 'app/components/customer/Product';
import * as selectors from 'app/selectors/customer';
import { useExperiment } from 'app/utils/useExperiment';

const CouponItem = ({ itemKey, discount, priceCalculator, isLabsFree }) => {
  const hideCouponCode = !discount.get('coupon') || discount.get('coupon').get('code').startsWith('internal-');
  const isLab = discount?.get('product_name')?.slice(-4) === '_lab';
  const appliedFor = isLab ? 'labs' : 'subscription';
  const price = priceCalculator.discountPrice(discount);

  if (price <= 0) return null;

  const couponCode = discount.get('coupon')?.get('code').toUpperCase();
  const couponName = discount.get('coupon')?.get('name') || discount.get('display');

  const getCouponMessage = () => {
    if (hideCouponCode) {
      return 'Discount Applied.';
    }
    if (isLabsFree) {
      return `"${couponCode}" applied.`;
    }
    return isLab ? `"${couponCode}" Applied for ${appliedFor}.` : `"${couponCode}" Applied.`;
  };

  return (
    <div key={itemKey} className="price_row">
      <div className="discount-message">
        <span className="coupon-applied">{getCouponMessage()}</span>
        <span className="coupon-description">{couponName}</span>
      </div>
      <div className="discount-amount">
        <PriceFormatter price={price} showCents={true} negative={true} className="new-price-format" size="small" />
      </div>
    </div>
  );
};

const DiscountCouponsList = ({ priceCalculator }) => {
  const productCtx = useContext(ProductContext);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, productCtx?.selectedProduct));
  const labsFreeExperiment =
    useExperiment('testosterone_discount_labs_free', 'testosterone_discount_labs_free_variation_0') ===
    'testosterone_discount_labs_free_variation_1';
  let discounts = product.get('discounts');
  const currentIntake = useAppSelector(selectors.selectCurrentIntake);
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const isOnboarding = currentIntake?.get('type') === 'onboarding';
  const isLabsFree = isOnboarding && currentProductName === 'king' && labsFreeExperiment;

  if (discounts == null) {
    const currentProduct = useAppSelector(selectManagedProduct);

    discounts = currentProduct.get('intakes').last().get('discounts');
  }

  const firstInstallmentDiscount =
    useExperiment('testosterone_first_installment_discount', 'testosterone_first_installment_discount_variation_0') ===
    'testosterone_first_installment_discount_variation_1';

  discounts = discounts?.reduce((acc, discount) => {
    if (
      !(
        isLabsFree &&
        discount &&
        discount.get('type') === 'Discounts::SubscriptionPaymentsOff' &&
        discount.get('params').get('percentage_off') === 100
      ) &&
      !(firstInstallmentDiscount && discount && ['tfid100p', 'tfid50p'].includes(discount.get('coupon')?.get('code')))
    ) {
      acc.push(discount);
    }
    return acc;
  }, []);

  return discounts?.length ? (
    <div className="discount-coupons-list discount-coupons-list--new">
      {discounts?.map((discount) => (
        <CouponItem
          key={discount.get('id')}
          itemKey={discount.get('id')}
          discount={discount}
          priceCalculator={priceCalculator}
          isLabsFree={isLabsFree}
        />
      ))}
    </div>
  ) : null;
};

export default DiscountCouponsList;
