/* eslint-disable no-case-declarations */
import { Discount } from 'app/types/admin/customerUser';
import { ImmutableList, ImmutableMap } from 'app/types/admin';
import KingPriceCalculator from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import LoverPriceCalculator from 'app/helpers/productSpecificPriceCalculators/loverPriceCalculator';
import MagicianPriceCalculator from 'app/helpers/productSpecificPriceCalculators/magicianPriceCalculator';
import WarriorPriceCalculator from 'app/helpers/productSpecificPriceCalculators/warriorPriceCalculator';

class PriceCalculator {
  productSpecificPriceCalculator:
    | KingPriceCalculator
    | LoverPriceCalculator
    | MagicianPriceCalculator
    | WarriorPriceCalculator;
  discounts: ImmutableList<Discount> | Discount[];

  constructor(
    productSpecificPriceCalculator:
      | KingPriceCalculator
      | LoverPriceCalculator
      | MagicianPriceCalculator
      | WarriorPriceCalculator,
    discounts: ImmutableList<Discount> | Discount[] = [],
  ) {
    this.productSpecificPriceCalculator = productSpecificPriceCalculator;
    this.discounts = discounts;
  }

  bbPrice = () => this.productSpecificPriceCalculator.bbPrice();

  dermastampPrice = () => this.productSpecificPriceCalculator.dermastampPrice();

  monthlyCost = () => this.productSpecificPriceCalculator.monthlyCost();

  monthlySubscriptionCost = () => this.productSpecificPriceCalculator.monthlySubscriptionCost();

  fullMonthlyCost = () => this.productSpecificPriceCalculator.fullMonthlyCost();

  montlyPayment = () => this.productSpecificPriceCalculator.montlyPayment();

  quarterlyPayment = () => this.productSpecificPriceCalculator.quarterlyPayment();

  yearlyPayment = () => this.productSpecificPriceCalculator.yearlyPayment();

  subscriptionCost = () => this.productSpecificPriceCalculator.subscriptionCost();

  appointmentCost = () => this.productSpecificPriceCalculator.appointmentCost();

  bbIncluded = () => this.productSpecificPriceCalculator.includeBB;

  bbDiscount = () => this.productSpecificPriceCalculator.bbDiscount();

  bbPotentialDiscount = () => this.productSpecificPriceCalculator.bbPotentialDiscount();

  atHomeLabPrice = () => this.productSpecificPriceCalculator.atHomeLabPrice();

  labCost = () => this.productSpecificPriceCalculator.labCost();

  monthlyInstallmentCost = () => {
    const cost = this.productSpecificPriceCalculator.subscriptionCost() - this.totalDiscountsValue();
    return Math.max(0, cost); // Prevent negative values
  };

  totalDueToday = () => {
    const cost = this.productSpecificPriceCalculator.totalDueTodayForCurrentProduct() - this.totalDiscountsValue();
    return Math.max(0, cost); // Prevent negative values
  };

  totalDiscountsValue() {
    let total = 0;

    this.discounts.map((discount) => {
      total += this.discountPrice(discount)!;
    });

    const maxDiscount = this.productSpecificPriceCalculator.totalDueTodayForCurrentProduct();
    // Round to nearest integer to avoid rounding issues
    return Math.round(Math.min(total, maxDiscount));
  }

  freeShipping = () => this.productSpecificPriceCalculator.freeShipping();

  shippingPrice = () => this.productSpecificPriceCalculator.shippingPrice();

  nonDiscountedPrice = () => this.productSpecificPriceCalculator.nonDiscountedPrice();

  discountPrice(discount: ImmutableMap<Discount>) {
    const isLab = discount?.get('product_name')?.slice(-4) === '_lab';
    const amountUsed = discount.get('uses').reduce((sum, use) => sum + use.get('amount_used'), 0);

    const discountParams = discount.get('params');
    const discountType = discount.get('type');
    const couponCode = discount.getIn(['coupon', 'code']);

    // Check if this is the first installment discount experiment with tfid coupon
    const isFirstInstallmentDiscount =
      this.productSpecificPriceCalculator instanceof KingPriceCalculator &&
      this.productSpecificPriceCalculator.isFirstInstallmentDiscount() &&
      ['tfid50p', 'tfid100p'].includes(couponCode);

    // We only apply special handling for SubscriptionPaymentsOffForever discount type
    const shouldApplySpecialHandling =
      isFirstInstallmentDiscount && discountType === 'Discounts::SubscriptionPaymentsOffForever';

    // Calculate product price
    let baseProductPrice;
    if (isLab) {
      baseProductPrice = this.labCost();
    } else if (shouldApplySpecialHandling) {
      // For first installment discount with SubscriptionPaymentsOffForever, only use main product price
      baseProductPrice = this.monthlyCost();
    } else {
      // Regular case - include everything
      baseProductPrice = this.fullMonthlyCost()!;
    }

    const monthlyInstallmentPlan = this.productSpecificPriceCalculator.monthly_installment_plan;
    const multiMonthPlan = monthlyInstallmentPlan ? 1 : this.productSpecificPriceCalculator.multiMonthPlan;

    switch (discountType) {
      case 'Discounts::SubscriptionPaymentsOffForever':
        const discountForeverPercentage = discountParams?.get('percentage_off');

        let basePriceForForeverDiscounted;
        if (isLab) {
          basePriceForForeverDiscounted = baseProductPrice;
        } else if (shouldApplySpecialHandling) {
          // Exclude BB and appointment from the discount calculation
          basePriceForForeverDiscounted = baseProductPrice * multiMonthPlan;
        } else {
          basePriceForForeverDiscounted = baseProductPrice * multiMonthPlan + this.appointmentCost();
        }

        return discountForeverPercentage * 0.01 * basePriceForForeverDiscounted;
      case 'Discounts::SubscriptionPaymentsOff':
        const discountPercentage = discountParams?.get('percentage_off');
        const discountedMonthPayments = discountParams?.get('number_of_payments');
        const discountedMonths = Math.min(multiMonthPlan || 0, discountedMonthPayments);

        const basePriceForDiscountedMonths = isLab ? baseProductPrice : baseProductPrice * discountedMonths;

        const totalDiscountedPrice = isLab
          ? basePriceForDiscountedMonths * discountPercentage * 0.01
          : basePriceForDiscountedMonths * discountPercentage * 0.01 - amountUsed;

        return totalDiscountedPrice > basePriceForDiscountedMonths
          ? basePriceForDiscountedMonths
          : totalDiscountedPrice;
      case 'Discounts::FixedAmountOff':
        if (this.productSpecificPriceCalculator.subscriptionPaid) return 0;

        const basePriceForDiscount = isLab ? baseProductPrice : baseProductPrice * multiMonthPlan;
        const discountAmount = discountParams?.get('fixed_amount_off') - amountUsed;

        return discountAmount > basePriceForDiscount ? basePriceForDiscount : discountAmount;
      default:
        throw new Error('Invalid discount type');
    }
  }
}

export default PriceCalculator;
