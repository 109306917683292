import React from 'react';
import { useFormikContext } from 'formik';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import { ImmutableList } from 'app/types/admin';
import { KingSubProductsConfig } from 'app/components/customer/steps/Payment/king/KingSubProductsConfig';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import subProductNames from 'app/utils/subProductNames';
import { kingMonthlyPriceExperiment } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import { Badge, Button, Card } from 'mui';
import { useExperiment } from 'app/utils/useExperiment';

type Props = {
  availableSubProducts: ImmutableList<KingV2SubProducts> | null;
  onProtocolChosen: (product: string) => void;
  isOnboarding?: boolean;
};

const KingSubProductsList = ({ availableSubProducts, onProtocolChosen, isOnboarding }: Props) => {
  const { setFieldValue } = useFormikContext();
  const experimentVariation = useExperiment('testosterone_pricing', 'testosterone_pricing_variation_0');
  const firstInstallmentDiscount =
    useExperiment('testosterone_first_installment_discount', 'testosterone_first_installment_discount_variation_0') ===
      'testosterone_first_installment_discount_variation_1' && isOnboarding;

  const renderProductCard = (subProductName: any) => {
    const subProduct = KingSubProductsConfig[subProductName];

    return (
      <Card>
        <Card.Body>
          <figure className="h-[180px] lg:h-[240px] mb-4">
            <img className="h-full w-auto" src={ImagesMapping[AvailableProducts.King][subProductName]} alt="king v2" />
          </figure>
          <div className="text-center">
            <Badge variant={AvailableProducts.King} className="mb-2">
              {protocolNames[AvailableProducts.King]}
            </Badge>
            <h4>{subProductNames[AvailableProducts.King][subProductName]}</h4>
          </div>
          <p>
            <b className="bold">Recommended for: </b>
            {subProduct.recommended_for}
          </p>
          <hr />
          {firstInstallmentDiscount ? (
            <div className="flex flex-col items-center text-center gap-y-1">
              <p className="font-inter font-bold text-[14px] leading-[20px] tracking-[0%] text-center align-middle mb-2">
                First month free*
              </p>
              <div className="flex items-center gap-x-1">
                <p className="text-center flex flex-row flex-center gap-x-1 mb-2">
                  ${Math.floor(kingMonthlyPriceExperiment(experimentVariation)[subProductName][12] / 100)}/mo after the
                  first month
                </p>
              </div>
              <p className="text-[9px]">*First month pricing varies upon the plan you choose</p>
            </div>
          ) : (
            <p className="text-center flex flex-row flex-center gap-x-1 mb-2">
              Plans starting at
              <PriceFormatter
                period="mo"
                size="small"
                price={kingMonthlyPriceExperiment(experimentVariation)[subProductName][12]}
              />
            </p>
          )}
          <Button
            data-testid="own-lab-modal-close"
            onClick={() => {
              setFieldValue('selected_king_v2_product', subProductName);
              if (onProtocolChosen) {
                onProtocolChosen(subProductName);
              }
            }}
          >
            Continue
          </Button>
        </Card.Body>
      </Card>
    );
  };

  if (!availableSubProducts) return null;

  return (
    <div className="flex flex-col gap-y-6 lg:gap-y-8">
      {availableSubProducts.map((subProductName) => renderProductCard(subProductName))}
    </div>
  );
};

export default KingSubProductsList;
