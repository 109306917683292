import { apiRequestUserCommand } from 'app/actions/customer';

export const applyFirstInstallmentDiscount = (multimonth_plan: number, productName: string) => {
  if (productName !== 'king') return;
  let promo_code;

  switch (multimonth_plan) {
    case 12:
      promo_code = 'tfid100p';
      break;
    case 3:
      promo_code = 'tfid50p';
      break;
    default:
      return;
  }
  try {
    return apiRequestUserCommand({
      cmdType: 'create_discount_with_promo_code',
      params: {
        promo_code,
        product_name: productName,
        multimonth_plan,
      },
    });
  } catch (e) {
    console.log('error', e);
  }
};
