import cx from 'classnames';
import InfoIcon from 'images/info-icon.svg';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import protocolNames from 'app/utils/protocolNames';
import React, { useState } from 'react';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import ModalBodySupplementInfo from 'app/components/customer/Checkout/ModalBodySupplementInfo';
import { useFormikContext } from 'formik';
import PriceCalculator from 'app/helpers/priceCalculator';
import { FormCheckbox } from 'mui';
import { AvailableProducts } from 'app/constants/Products';

type Props = {
  currentProductName: string;
  activeProductWithSupplementName: string | undefined;
  multimonthPlan: number;
  supplementsOnAnotherIntake: boolean;
  bbOutOfStock: boolean;
  priceCalculator: PriceCalculator;
  isOnboarding: boolean;
  starterPack: boolean;
};

const GenericBuildingBlocks = ({
  activeProductWithSupplementName,
  bbOutOfStock,
  multimonthPlan,
  priceCalculator,
  supplementsOnAnotherIntake = false,
  isOnboarding,
  starterPack,
  currentProductName,
}: Props) => {
  const [openModalSupplementInfo, setOpenModalSupplementInfo] = useState(false);
  const { values, setFieldValue } = useFormikContext<{
    include_supplement: boolean;
  }>();
  const { include_supplement } = values;

  if (values.include_supplement === null && !supplementsOnAnotherIntake) {
    // Default to checked state if onboarding, unless bbOutOfStock is true or supplementsOnAnotherIntake is true
    values.include_supplement = isOnboarding;
  }

  const firstInstallmentDiscount =
    priceCalculator.productSpecificPriceCalculator.monthly_installment_plan &&
    isOnboarding &&
    currentProductName === AvailableProducts.King;

  const bbOutOfStockWidget = () =>
    bbOutOfStock && (
      <div className="generic_payment_page__plan_summary plan_summary">
        <p className="font-bold">
          Due to high demand, the Building Blocks supplement is currently out of stock, but it will be restocked soon.
        </p>
        <p>Pre-order Building Blocks by checking the box above.</p>
        <p>
          You won{"'"}t be charged until it{"'"}s restocked, and then $49.99 will be added to your subscription for
          future shipments
        </p>
        <p className="mt-3">
          Note: The total monthly subscription reflects the Building Blocks pre-order, but you{"'"}ll only be charged
          the full amount once it{"'"}s back in stock.
        </p>
      </div>
    );

  const bbMonthlyInstallmentDiscount = () => {
    const { multiMonthPlan } = priceCalculator.productSpecificPriceCalculator;
    if (![3, 12].includes(multiMonthPlan)) return null;

    if (multiMonthPlan === 3) {
      return <p className="font-[700] text-[12px]">$5 Off with Quarterly Plan</p>;
    }

    return <p className="font-[700] text-[12px]">$15 Off with Yearly Plan</p>;
  };

  return (
    <>
      <div>
        <div className="flex flex-row justify-between items-center">
          <FormCheckbox
            wrapperClassName="mb-2"
            className="lg:mt-2"
            label={
              <div className={cx({ opacity: !include_supplement })}>
                <div>
                  <h5 className={cx({ 'text-primary-200': supplementsOnAnotherIntake })}>
                    Add Building Blocks{supplementsOnAnotherIntake || starterPack ? '' : ':'}
                    {starterPack && <div className="paragraph-light-normal mb-2">({multimonthPlan} months)</div>}
                  </h5>
                </div>
              </div>
            }
            testid="include-supplement"
            name="include_supplement"
            checked={!supplementsOnAnotherIntake && !bbOutOfStock && values.include_supplement}
            disabled={supplementsOnAnotherIntake || bbOutOfStock}
            onChange={(evt) => setFieldValue('include_supplement', evt.target.checked)}
          />
          <PriceFormatter
            className={cx('font-medium', { opacity: !include_supplement })}
            price={priceCalculator.bbPrice() * (starterPack ? multimonthPlan : 1)}
            period={starterPack ? '' : 'mo'}
          />
        </div>
        <p>
          {supplementsOnAnotherIntake && activeProductWithSupplementName ? (
            `You are already subscribed to Building Blocks as part of your ${protocolNames[activeProductWithSupplementName]} Protocol subscription.`
          ) : (
            <>
              {starterPack ? (
                <>
                  Taking a daily multivitamin alongside your GLP-1 medication helps protect you against nutritional
                  gaps, such as vitamin B12 deficiency, ensuring your body gets all the nutrients it needs while you
                  lose weight. Maximus’ Building Blocks is a prescription-strength multivitamin specifically designed to
                  support your body for maximum results.
                </>
              ) : (
                <>
                  {firstInstallmentDiscount && bbMonthlyInstallmentDiscount()}
                  Prescription-strength multivitamin that supports hormone optimization & energy production.
                </>
              )}
              <button onClick={() => setOpenModalSupplementInfo(true)} aria-label="Info">
                <img className="clickable inline ml-3" src={InfoIcon} alt="" />
              </button>
            </>
          )}
        </p>
      </div>
      {!supplementsOnAnotherIntake && bbOutOfStockWidget()}
      <SetProductDialog
        isOpen={openModalSupplementInfo}
        text={<ModalBodySupplementInfo />}
        onClose={() => setOpenModalSupplementInfo(false)}
        className="manage_subscription__cancel_modal supplement_info align-left maximized"
      />
    </>
  );
};

export default GenericBuildingBlocks;
