import React, { ComponentProps, useMemo, useState } from 'react';
import * as selectors from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { AvailableProducts } from 'app/constants/Products';
import WhyCancelModal from 'app/components/customer/dashboard/WhyCancelModal';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import InfoIcon from 'images/INFO.svg';
import * as Routes from 'app/constants/Routes';
import dayjs from 'dayjs';
import { selectCustomerStripeInfo } from 'app/selectors/admin';
import protocolNames from 'app/utils/protocolNames';
import CouponCodeInput from 'app/components/customer/steps/Payment/CouponCodeInput';
import { Formik } from 'formik';
import { Badge, Button, Card, PageWrapper } from 'mui';
import PauseSubscription from 'app/components/customer/dashboard/PauseSubscription';
import { FullscreenModal } from 'app/components/common/FullscreenModal';
import { MultiMonthPlanMapping } from 'app/constants/Products';

const CouponItem = ({ key, discount }) => {
  const isLab = discount['product_name']?.slice(-4) === '_lab';
  const appliedFor = isLab ? 'labs' : 'subscription';
  const hideCouponCode = !discount['coupon'] || discount['coupon']['code'].startsWith('internal-');

  return (
    <div key={key} className="mt-6">
      <div>
        {hideCouponCode ? 'Discount' : `“${discount['coupon']['code']}”`} will be applied for {appliedFor}.
        <br />
        {discount['coupon'] ? discount['coupon']['name'] : discount['display']}
      </div>
    </div>
  );
};

const DiscountItems = ({ products, productName }) => {
  const discounts = products[productName]['discounts'] || [];

  return (
    <>
      {discounts.length > 0 && (
        <div>
          <div className="manage_subscription__column">
            <p className="bold">Discounts</p>
            <div className="discount-coupons-list">
              {discounts
                .filter((discount) => !['tfid100p', 'tfid50p'].includes(discount['coupon']['code']))
                .map((discount) => (
                  <CouponItem key={discount['id']} discount={discount} />
                ))}
            </div>
          </div>
        </div>
      )}

      <div>
        <div className="manage_subscription__column">
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <Formik initialValues={{}} onSubmit={() => {}}>
            <CouponCodeInput productName={productName} />
          </Formik>
        </div>
      </div>
    </>
  );
};

const ModalBodySuccess = ({ productForCancel, setOpenCancelSuccess }) => (
  <>
    <p className="my-3">Your {protocolNames[productForCancel || '']} Protocol has been canceled.</p>
    <p>You can un-cancel anytime by visiting the Manage Subscription section of the Settings Page</p>
    <hr className="my-6" />
    <Button onClick={() => setOpenCancelSuccess(false)}>Ok</Button>
  </>
);

const ModalBody = ({ productForCancel, handleCancelClick, setOpenCancel }) =>
  productForCancel ? (
    <>
      <h6>The {protocolNames[productForCancel]} Protocol</h6>
      <div className="flex flex-row gap-x-6 items-center">
        <img src={InfoIcon} alt="Info" />
        <p>
          Are you sure you want to cancel
          <br />
          the {protocolNames[productForCancel]} Protocol subscription?
        </p>
      </div>
      <hr />
      <Button className="dont" onClick={() => setOpenCancel(false)}>
        Don{"'"}t Cancel
      </Button>
      <Button variant="text" onClick={handleCancelClick}>
        Proceed to cancel
      </Button>
    </>
  ) : null;

const ManageSubscriptions = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectors.selectCustomerId);
  const productsImm = useAppSelector(selectors.selectCustomerProducts);
  const customer = useAppSelector(selectors.selectCustomer);

  const [openCancel, setOpenCancel] = useState(false);
  const [openCancelReasons, setOpenCancelReasons] = useState(false);
  const [openCancelSuccess, setOpenCancelSuccess] = useState(false);
  const [productForCancel, setProductForCancel] = useState<null | string>(null);

  const [pauseSubscriptionModal, setPauseSubscriptionModal] = useState(false);
  const [productForPause, setProductForPause] = useState<null | string>(null);

  const stripeInfo = useAppSelector((state) => selectCustomerStripeInfo(state, customerId));
  const nextBillingCycle = (stripeProductId: string) => {
    const subscription = (stripeInfo?.get('subscriptions')?.toJS() || []).find((s) => s.id === stripeProductId);
    if (!subscription) {
      return '';
    }

    return dayjs(subscription.current_period_end * 1000).format('MMMM D, YYYY');
  };
  const products = useMemo(() => productsImm.toJS(), [productsImm]);
  const activeProductWithSupplementName = customer.get('active_product_with_supplement_name');

  const reactivate = (product_name: AvailableProducts) => {
    const supplementsOnAnotherIntake =
      activeProductWithSupplementName && activeProductWithSupplementName != product_name;

    if (supplementsOnAnotherIntake && productsImm?.get(product_name)?.get('opt_in_choice')?.get('include_supplement')) {
      handleUpdateClick(product_name);
    } else {
      const uncancelParams = {
        type: 'uncancel_account',
        user_id: customerId,
        params: { product_name },
      };
      const uncancelAction = actions.apiRequestCommand({
        params: uncancelParams,
        context: {},
      });
      dispatch(uncancelAction);
    }
  };

  const resumeSubscription = (product_name: AvailableProducts) => {
    const action = actions.apiRequestUserCommand({
      cmdType: 'resume_subscription',
      params: {
        product_name,
      },
      context: {},
    });

    dispatch(action);
  };

  const openPauseModal = (product: string) => {
    setProductForPause(product);
    setPauseSubscriptionModal(true);
  };

  const openCancelModal = (product: string) => {
    setProductForCancel(product);
    if (product === AvailableProducts.King) {
      setOpenCancelReasons(true);
    } else {
      setOpenCancel(true);
    }
  };

  const handleCancelClick = () => {
    setOpenCancel(false);
    setOpenCancelReasons(true);
  };

  const handleUpdateClick = (productName: string) => {
    dispatch(push(Routes.UpdateSubscriptionForProduct(productName)));
  };

  const getNextBillingCycle = (productName) => {
    if (customer.get('spreedly_enabled')) {
      return products[productName]?.current_subscription?.next_billing_date_str;
    } else {
      return nextBillingCycle(products[productName].current_subscription?.stripe_internal_id);
    }
  };

  const getPauseSubscriptionDate = (product, field) => {
    const resumedAt = customer?.get('maximus_products')?.get(product)?.get('resume_at');

    if (resumedAt && dayjs(resumedAt).isAfter(dayjs())) {
      return customer?.get('maximus_products')?.get(product)?.get(field);
    }
  };

  const currentStatus = (product) => {
    const pausedAt = getPauseSubscriptionDate(product, 'pause_at');

    if (products[product].cancelled_at) {
      return 'Canceled';
    } else if (products[product].current_status == 'paused') {
      return `Paused until ${getNextBillingCycle(product)}`;
    } else if (pausedAt) {
      return `Pauses on ${dayjs(pausedAt).format('MMMM D, YYYY')}`;
    } else {
      return 'Active';
    }
  };

  const currentStatusVariant = (product) => {
    const pausedAt = getPauseSubscriptionDate(product, 'pause_at');

    if (products[product].cancelled_at) {
      return 'danger';
    } else if (pausedAt || products[product].current_status == 'paused') {
      return 'warning';
    } else {
      return 'success';
    }
  };

  return (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <h3 className="ml-10 sm:ml-5">Manage Subscriptions</h3>
      {Object.keys(products).map(
        (productName) =>
          products[productName]?.payment_successful && (
            <Card key={productName}>
              <Card.Body>
                <h4>{protocolNames[productName]} protocol</h4>
                <div className="flex flex-col gap-y-2">
                  <h5>Subscription status</h5>
                  <Badge variant={currentStatusVariant(productName)}>{currentStatus(productName)}</Badge>
                </div>
                <div className="flex flex-col gap-y-2">
                  <h5>Current subscription</h5>
                  <p className="whitespace-pre-line">{products[productName].current_subscription?.friendly_name}</p>
                </div>
                {products[productName].current_subscription?.friendly_name !==
                  products[productName].pending_subscription?.friendly_name &&
                  !!products[productName].intakes[products[productName].intakes.length - 1]?.billing_step_completed && (
                    <div className="flex flex-col gap-y-2">
                      <h5>Pending subscription</h5>
                      <p>{products[productName].pending_subscription?.friendly_name}</p>
                      {products[productName].opt_in_choice.supplement_preorder &&
                        products[productName].opt_in_choice.include_supplement && (
                          <p className="mt-2">
                            * Your request to add Building Blocks to your subscription has been successfully received.
                            When Building Blocks is back in stock your subscription will update automatically and you
                            will begin to receive Building Blocks with future orders.
                          </p>
                        )}
                    </div>
                  )}
                {(!products[productName].cancelled_at || products[productName].has_active_installment_plan) && (
                  <div className="flex flex-col gap-y-2">
                    <h5>Next billing cycle</h5>
                    <p>
                      {customer.get('spreedly_enabled') &&
                        products[productName].current_subscription?.next_billing_date_str}
                      {!customer.get('spreedly_enabled') &&
                        nextBillingCycle(products[productName].current_subscription?.stripe_internal_id)}
                    </p>
                  </div>
                )}
                <DiscountItems products={products} productName={productName} />

                <Button onClick={() => handleUpdateClick(productName)}>Change Subscription</Button>
                {!!products[productName].cancelled_at && (
                  <Button onClick={() => reactivate(productName as AvailableProducts)}>Activate Subscription</Button>
                )}
                {!products[productName].cancelled_at &&
                  !products[productName].has_active_installment_plan &&
                  getPauseSubscriptionDate(productName, 'resume_at') && (
                    <Button onClick={() => resumeSubscription(productName as AvailableProducts)}>
                      Resume Subscription
                    </Button>
                  )}
                {!products[productName].cancelled_at && !products[productName].has_active_installment_plan && (
                  <Button variant="outline" className="full" onClick={() => openPauseModal(productName)}>
                    {getPauseSubscriptionDate(productName, 'resume_at') ? 'Change Pause' : 'Pause Subscription'}
                  </Button>
                )}
                {!products[productName].cancelled_at && (
                  <Button onClick={() => openCancelModal(productName)} variant="text">
                    Cancel Subscription
                  </Button>
                )}
                <div className="bg-main-grey rounded-xl p-4 lg:p-8">
                  {!products[productName].cancelled_at && (
                    <span>Note: Updates to your subscription will go into effect on your next billing cycle.</span>
                  )}
                  {products[productName].cancelled_at && !products[productName].has_active_installment_plan && (
                    <span>
                      Your subscription is canceled. You will not be charged on your next billing cycle unless you
                      choose to reactivate your subscription. If you reactivate before the billing cycle, you will keep
                      your same billing cycle. If you choose to reactivate your account after the billing cycle, a new
                      billing cycle will be set based on the date you reactivate.
                    </span>
                  )}
                  {products[productName].cancelled_at && products[productName].has_active_installment_plan && (
                    <>
                      <p>
                        Your plan is canceled and will not be renewed at the end of the{' '}
                        {MultiMonthPlanMapping[
                          products[productName].current_subscription?.multimonth_plan || 1
                        ].toLowerCase()}{' '}
                        plan. However, monthly payments will continue until{' '}
                        {products[productName].current_subscription?.next_subscription_date_str} and you will continue
                        to receive our products as usual.
                      </p>
                      <p>
                        If you have further questions, you can reach out to customer service at{' '}
                        <a href="mailto:support@maximustribe.com" target="_blank" rel="noreferrer">
                          support@maximustribe.com
                        </a>
                      </p>
                    </>
                  )}
                </div>

                {openCancelReasons && (
                  <WhyCancelModal
                    productName={productForCancel as ComponentProps<typeof WhyCancelModal>['productName']}
                    openCancelReasons={openCancelReasons}
                    setOpenCancelReasons={setOpenCancelReasons}
                    setOpenCancelSuccess={setOpenCancelSuccess}
                  />
                )}

                {productForCancel && (
                  <SetProductDialog
                    isOpen={openCancelSuccess}
                    title="Cancelation confirmation"
                    text={
                      <ModalBodySuccess
                        productForCancel={productForCancel}
                        setOpenCancelSuccess={setOpenCancelSuccess}
                      />
                    }
                    onClose={() => setOpenCancelSuccess(false)}
                    className="manage_subscription__cancel_modal text-left"
                  />
                )}

                <SetProductDialog
                  isOpen={openCancel}
                  title="Cancel Subscription"
                  text={
                    <ModalBody
                      productForCancel={productForCancel}
                      handleCancelClick={handleCancelClick}
                      setOpenCancel={setOpenCancel}
                    />
                  }
                  onClose={() => setOpenCancel(false)}
                  className="manage_subscription__cancel_modal"
                />
                {pauseSubscriptionModal && productForPause && (
                  <FullscreenModal
                    isOpen={pauseSubscriptionModal}
                    onClose={() => setPauseSubscriptionModal(false)}
                    text={
                      <PauseSubscription
                        setPauseSubscriptionModal={setPauseSubscriptionModal}
                        nextBillingCycle={getNextBillingCycle(productForPause)}
                        product={productForPause}
                        subProduct={products[productForPause]?.opt_in_choice?.selected_king_v2_product}
                        resumeAt={getPauseSubscriptionDate(productForPause, 'resume_at')}
                        pausedAt={getPauseSubscriptionDate(productForPause, 'pause_at')}
                        paused={products[productForPause].paused}
                      />
                    }
                  />
                )}
              </Card.Body>
            </Card>
          ),
      )}
    </PageWrapper>
  );
};

export default ManageSubscriptions;
