import React from 'react';

import '../css/Payment.scss';
import GenericBuildingBlocks from 'app/components/customer/steps/Payment/Generic/GenericBuildingBlocks';
import GenericOnboardingServices from 'app/components/customer/steps/Payment/Generic/GenericOnboardingServices';
import nextBillingCycle from 'app/helpers/nextBillingCycle';
import '../css/GenericPaymentPage.scss';
import PriceCalculator from 'app/helpers/priceCalculator';
import { CheckoutHelper } from 'app/types/admin/customerUser';
import { AvailableProducts } from 'app/constants/Products';
import { Badge } from 'mui';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import subProductNames from 'app/utils/subProductNames';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import cx from 'classnames';
import { useExperiment } from 'app/utils/useExperiment';

type Props = {
  currentProductName: AvailableProducts;
  multimonthPeriod: string;
  multimonthPlan: number;
  subProductName: string;
  checkoutHelper: CheckoutHelper;
  isOnboarding: boolean;
  supplementsOnAnotherIntake: boolean;
  activeProductWithSupplementName: string | undefined;
  productChanged: boolean;
  priceCalculator: PriceCalculator;
};

const SubscriptionWidget = ({
  currentProductName,
  priceCalculator,
  multimonthPeriod,
  multimonthPlan,
  isOnboarding,
  supplementsOnAnotherIntake,
  activeProductWithSupplementName,
  checkoutHelper,
  productChanged,
  subProductName,
}: Props) => {
  const SubscriptionSummaryComponent = checkoutHelper.subscriptionSummaryComponent();
  const starterPack = checkoutHelper.productSpecificInitialValues()['starter_pack'];
  const drugVariant = checkoutHelper.productSpecificInitialValues()['drug_variant'];

  const starterDosageText = drugVariant === 'tirzepatide' ? ' 2.5mg, 5mg, and 7.5mg' : ' 0.25mg, 0.5mg, and 1mg';

  const firstInstallmentDiscount =
    useExperiment('testosterone_first_installment_discount', 'testosterone_first_installment_discount_variation_0') ===
      'testosterone_first_installment_discount_variation_1' &&
    isOnboarding &&
    currentProductName === AvailableProducts.King;

  const discountPercentages = {
    12: '100%',
    3: '50%',
  };

  const firstInstallmentDescriptionText = {
    12: 'A year of personalized treatment from Maximus.',
    3: '3 months of expert treatment on Maximus.',
    1: 'Month-to-month expert treatment on Maximus.',
  };

  const firstInstallmentDiscountText = firstInstallmentDiscount ? discountPercentages[multimonthPlan] || null : null;

  return (
    <>
      <figure className="h-[180px] lg:h-[240px] mb-4">
        <img
          className="h-full w-auto"
          src={
            currentProductName == AvailableProducts.Lover
              ? ImagesMapping[currentProductName]
              : ImagesMapping[currentProductName][subProductName]
          }
          alt="Product"
        />
      </figure>
      <div className="text-center">
        <Badge variant={currentProductName} className="mb-2">
          {protocolNames[currentProductName]}
        </Badge>
        {currentProductName == AvailableProducts.Lover && <h4>Vardenafil + Tadalafil</h4>}
        <h4>{subProductNames[currentProductName][subProductName]}</h4>
      </div>

      <hr />

      <div className={cx({ 'first-installment-discount': firstInstallmentDiscount })}>
        <div className="flex flex-row justify-between items-center">
          <h5>
            {starterPack ? 'Starter Pack' : `${multimonthPeriod} Plan`}
            {multimonthPlan && (
              <span className="paragraph-light-normal">
                {' '}
                ({multimonthPlan} {multimonthPlan === 1 ? 'month' : 'months'})
              </span>
            )}
          </h5>
          <PriceFormatter
            price={priceCalculator.monthlyCost()}
            className="font-medium"
            period={firstInstallmentDiscount || starterPack ? '' : 'mo'}
            free={firstInstallmentDiscount && [12, 3].includes(multimonthPlan)}
            discountPrice={
              firstInstallmentDiscount && multimonthPlan === 3 ? (
                <PriceFormatter
                  price={(priceCalculator.monthlyCost() - priceCalculator.totalDiscountsValue()) as number}
                  className="font-medium"
                />
              ) : undefined
            }
          />
        </div>
        {firstInstallmentDiscountText && (
          <p className="mt12 mb12 font-bold">{firstInstallmentDiscountText} off your first month</p>
        )}
        {starterPack ? (
          <p className="my-3">
            Starter Pack includes {multimonthPlan} months of treatment for one low price and covers dosage titration of
            {starterDosageText} for months 1, 2, 3.
          </p>
        ) : (
          <p className="my-3">
            {firstInstallmentDiscount
              ? firstInstallmentDescriptionText[multimonthPlan]
              : 'Covers cost of prescription medication (if qualified), doctor consultation, and psychologist-led health coaching.'}
          </p>
        )}
      </div>
      {checkoutHelper.enableBuildingBlocks() && (
        <GenericBuildingBlocks
          currentProductName={currentProductName}
          starterPack={starterPack}
          supplementsOnAnotherIntake={supplementsOnAnotherIntake}
          multimonthPlan={multimonthPlan}
          priceCalculator={priceCalculator}
          bbOutOfStock={checkoutHelper.bbOutOfStock()}
          activeProductWithSupplementName={activeProductWithSupplementName}
          isOnboarding={isOnboarding}
        />
      )}
      {isOnboarding && (
        <GenericOnboardingServices
          firstInstallmentDiscount={firstInstallmentDiscount}
          multimonthPlan={multimonthPlan}
        />
      )}
      <SubscriptionSummaryComponent
        starterPack={starterPack}
        multimonthPeriod={multimonthPeriod}
        currentProductName={currentProductName}
        multimonthPlan={multimonthPlan}
        priceCalculator={priceCalculator}
        isOnboarding={isOnboarding}
        productChanged={productChanged}
        nextBillingCycle={nextBillingCycle(currentProductName)}
      />
    </>
  );
};

export default SubscriptionWidget;
