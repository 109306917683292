import React from 'react';
import { useFormikContext } from 'formik';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import { KingSubProductsConfig } from 'app/components/customer/steps/Payment/king/KingSubProductsConfig';
import SlidersIcon from 'images/icons/sliders.svg';
import IngredientIcon from 'images/icons/ingredient.svg';
import FreeshippingIcon from 'images/icons/fast-free-shipping-2.svg';
import AtHomeIcon from 'images/icons/at-home-monitoring-2.svg';
import { EnclomipheneInfo } from 'app/components/customer/steps/Payment/king/EnclomipheneInfo';
import { HcgInfo } from 'app/components/customer/steps/Payment/king//HcgInfo';
import { TopicalTestosteroneInfo } from 'app/components/customer/steps/Payment/king/TopicalTestosteroneInfo';
import { OralTestosteroneInfo } from 'app/components/customer/steps/Payment/king/OralTestosteroneInfo';
import { InjectableTestosteroneInfo } from 'app/components/customer/steps/Payment/king//InjectableTestosteroneInfo';
import { kingMonthlyPriceExperiment } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { Accordion, Badge, Button, Card, IconList } from 'mui';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import subProductNames from 'app/utils/subProductNames';
import { useExperiment } from 'app/utils/useExperiment';

type Props = {
  handleSubmit: () => void;
  onExploreOtherProtocols: () => void;
  isOnboarding?: boolean;
};

const IngredientIconComponent = () => <img src={IngredientIcon} alt="ingredient" />;
const AtHomeIconComponent = () => <img src={AtHomeIcon} alt="at home monitoring" />;
const FreeshippingIconComponent = () => <img src={FreeshippingIcon} alt="free shipping" />;
const SlidersIconComponent = () => <img src={SlidersIcon} alt="at home monitoring" />;

const KingSubProductScreen = ({ handleSubmit, onExploreOtherProtocols, isOnboarding }: Props) => {
  const { values, setFieldValue } = useFormikContext<{
    selected_king_v2_product: KingV2SubProducts;
  }>();

  const subProduct = KingSubProductsConfig[values.selected_king_v2_product];
  const experimentVariation = useExperiment('testosterone_pricing', 'testosterone_pricing_variation_0');

  const handleExploreOtherProtocols = () => {
    if (onExploreOtherProtocols) {
      onExploreOtherProtocols();
    }
    setFieldValue('selected_king_v2_product', false);
  };

  const firstInstallmentDiscount =
    useExperiment('testosterone_first_installment_discount', 'testosterone_first_installment_discount_variation_0') ===
      'testosterone_first_installment_discount_variation_1' && isOnboarding;

  const getProductInfo = (productName) => {
    switch (productName) {
      case 'topical_trt':
        return <TopicalTestosteroneInfo />;
      case 'oral_trt':
        return <OralTestosteroneInfo />;
      case 'injectable_trt':
        return <InjectableTestosteroneInfo />;
      case 'enclomiphene':
        return <EnclomipheneInfo />;
      case 'enclomiphene_topical_trt':
        return (
          <>
            <TopicalTestosteroneInfo />
            <EnclomipheneInfo />
          </>
        );
      case 'enclomiphene_oral_trt':
        return (
          <>
            <OralTestosteroneInfo />
            <EnclomipheneInfo />
          </>
        );
      case 'enclomiphene_injectable_trt':
        return (
          <>
            <InjectableTestosteroneInfo />
            <EnclomipheneInfo />
          </>
        );
      case 'injectable_trt_hcg':
        return (
          <>
            <InjectableTestosteroneInfo />
            <HcgInfo />
          </>
        );
        break;
      default:
        return null;
    }
  };

  const renderFooter = () => (
    <div className="fixed-footer">
      <div className="m-auto max-w-[26rem]">
        {firstInstallmentDiscount ? (
          <div className="flex flex-col items-center text-center gap-y-1">
            <p className="font-inter font-bold text-[14px] leading-[20px] tracking-[0%] text-center align-middle mb-2">
              First month free*
            </p>
            <div className="flex items-center gap-x-1">
              <p className="text-center flex flex-row flex-center gap-x-1 mb-2">
                $
                {Math.floor(kingMonthlyPriceExperiment(experimentVariation)[values.selected_king_v2_product][12] / 100)}
                /mo after the first month
              </p>
            </div>
            <p className="text-[9px]">*First month pricing varies upon the plan you choose</p>
          </div>
        ) : (
          <p>
            Plans Starting at $
            {kingMonthlyPriceExperiment(experimentVariation)[values.selected_king_v2_product][12] / 100}/mo
          </p>
        )}

        <Button
          className="mt-4 mb-4"
          type="button"
          onClick={handleSubmit}
          disabled={false}
          testid="submit-product-selection"
        >
          Continue with this Protocol
        </Button>

        <p>
          <button onClick={() => handleExploreOtherProtocols()} className="link bold">
            Explore Other Treatments
          </button>
        </p>
      </div>
    </div>
  );

  return (
    <>
      <Card>
        <Card.Body>
          <figure className="h-[180px] lg:h-[240px] mb-4">
            <img
              className="h-full w-auto"
              src={ImagesMapping[AvailableProducts.King][values.selected_king_v2_product]}
              alt="king v2"
            />
          </figure>
          <div className="text-center">
            <Badge variant={AvailableProducts.King} className="mb-2">
              {protocolNames[AvailableProducts.King]}
            </Badge>
            <h4>{subProductNames[AvailableProducts.King][values.selected_king_v2_product]}</h4>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h5>Treatment Details</h5>
          <p>{subProduct.recommended_for}</p>
          <hr />
          <h5>Active Ingredients</h5>
          {subProduct.active_ingredients.map((ingredient: { name: string; description: string }) => (
            <>
              <IconList
                key={ingredient.name}
                items={[
                  {
                    title: ingredient.name,
                    text: ingredient.description,
                    icon: IngredientIconComponent,
                  },
                ]}
              />
              <hr />
            </>
          ))}
          <h5>What to Expect</h5>
          <IconList
            items={[
              {
                title: 'Check Your Levels',
                text: 'Receive your At-Home Lab Kit to check your testosterone levels quickly.',
                icon: AtHomeIconComponent,
              },
              {
                title: 'Get Your Prescription',
                text: 'Once approved, your prescription is shipped straight to your door.',
                icon: FreeshippingIconComponent,
              },
              {
                title: 'Continuous Optimization',
                text:
                  'After your first month, we send another lab kit to ensure progress. Your doctor will adjust your dosage\n' +
                  ' for optimal results.',
                icon: SlidersIconComponent,
              },
            ]}
          />
          <div className="-mb-8 md:-mb-12 lg:-mb-8">
            <hr />
            <Accordion
              items={[
                {
                  title: 'Side Effects',
                  content: subProduct.side_effects,
                },
                {
                  title: 'Important safety information',
                  content: getProductInfo(values.selected_king_v2_product),
                },
              ]}
            />
          </div>
        </Card.Body>
      </Card>
      {renderFooter()}
    </>
  );
};

export default KingSubProductScreen;
