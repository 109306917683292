import React from 'react';
import { Formik } from 'formik';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import GenericMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericMultimonthPlanSelectionForm';
import { MultimonthConfig } from 'app/components/customer/steps/Payment/king/KingMultimonthPlanSelectionForm';
import { AvailableProducts } from 'app/constants/Products';
import { kingMonthlyPriceExperiment } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { useExperiment } from 'app/utils/useExperiment';
import { PageWrapper } from 'mui';

const StepMultimonthPlanSelection = ({ initialValues, onSubmit }) => {
  const experimentVariation = useExperiment('testosterone_pricing', 'testosterone_pricing_variation_0');

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, handleSubmit }) => (
        <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
          <h3>Choose Your Plan</h3>
          <GenericMultimonthPlanSelectionForm
            productName={AvailableProducts.King}
            selectedSubProduct={initialValues.selected_king_v2_product}
            multimonthConfig={MultimonthConfig}
            priceConfig={kingMonthlyPriceExperiment(experimentVariation)}
            isOnboarding={true}
          />
          <BackNextFooter
            noBack={true}
            onNext={handleSubmit}
            testId="submit-plan-selection"
            nextDisabled={!values.multimonth_plan}
          />
        </PageWrapper>
      )}
    </Formik>
  );
};

export default StepMultimonthPlanSelection;
