import React from 'react';

const GenericOnboardingServices = ({
  firstInstallmentDiscount,
  multimonthPlan,
}: {
  firstInstallmentDiscount?: boolean;
  multimonthPlan?: number;
}) => {
  const plansList = () => {
    return (
      <ul className="disc-list">
        <li>Personalized medication</li>
        <li>Doctor messaging</li>
        <li>Treatment customization</li>
        <li>Concierge support & coaching</li>
        <li>Access to private community</li>
        {multimonthPlan !== 1 && <li>Free shipping</li>}
      </ul>
    );
  };

  return (
    <div className="border border-primary-200 rounded-xl p-4 lg:p-8">
      <p className="mb-2 lg:mb-4">
        <b>Plan includes: </b>
      </p>
      {firstInstallmentDiscount ? (
        plansList()
      ) : (
        <ul className="disc-list">
          <li>Medication</li>
          <li>Doctor messaging</li>
          <li>Concierge support & coaching</li>
          <li>Free shipping</li>
        </ul>
      )}
    </div>
  );
};

export default GenericOnboardingServices;
