import React from 'react';
import { useFormikContext } from 'formik';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import nextBillingCycle from 'app/helpers/nextBillingCycle';
import KingSubProductsList from 'app/components/customer/steps/Payment/king/KingSubProductsList';
import KingSubProductScreen from 'app/components/customer/steps/Payment/king/KingSubProductScreen';
import { trackEvent } from 'app/utils/useCommonTracking';
import ProductChangeNote from '../ProductChangeNote';

type Props = {
  firstTimeChoice?: boolean;
  availableSubProducts: any;
  handleSubmit: () => void;
  isOnboarding?: boolean;
};

const KingProductSelectionForm = ({ availableSubProducts, firstTimeChoice, handleSubmit, isOnboarding }: Props) => {
  const { values } = useFormikContext<{
    selected_king_v2_product: string;
  }>();
  const nextBillingCycleStr = nextBillingCycle(AvailableProducts.King);

  const renderAlert = () => <ProductChangeNote nextBillingCycleStr={nextBillingCycleStr} />;

  const trackExploreOtherProtocols = () => {
    trackEvent('Clicked Explore Other Protocols', { protocol: 'king' });
  };

  const trackProtocolChosen = (product: string) => {
    try {
      trackEvent('Chose a Protocol', { protocol: 'king', product });
    } catch (error) {
      console.error('Error tracking protocol chosen', error);
    }
  };

  const renderBody = () => {
    const selectedProduct = values.selected_king_v2_product;

    if (
      !selectedProduct ||
      selectedProduct === KingV2SubProducts.EncloPregnolone ||
      selectedProduct === KingV2SubProducts.EncloPregnoloneTrt
    ) {
      return (
        <KingSubProductsList
          availableSubProducts={availableSubProducts}
          onProtocolChosen={trackProtocolChosen}
          isOnboarding={isOnboarding}
        />
      );
    }

    return (
      <KingSubProductScreen
        handleSubmit={handleSubmit}
        onExploreOtherProtocols={trackExploreOtherProtocols}
        isOnboarding={isOnboarding}
      />
    );
  };

  return (
    <>
      {!firstTimeChoice && renderAlert()}
      {renderBody()}
    </>
  );
};

export default KingProductSelectionForm;
