import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import { KingSubProductsConfig } from 'app/components/customer/steps/Payment/king/KingSubProductsConfig';
import { kingMonthlyPriceExperiment } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { Button, Card } from 'mui';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import subProductNames from 'app/utils/subProductNames';
import { useExperiment } from 'app/utils/useExperiment';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import { trackEvent } from 'app/utils/useCommonTracking';
import cx from 'classnames';

const RecommendationFlowAddon = () => {
  const { values, setFieldValue, handleSubmit } = useFormikContext<{
    selected_king_v2_product: KingV2SubProducts;
  }>();

  let suggestedSubProduct: KingV2SubProducts | null = null;
  switch (values.selected_king_v2_product) {
    case KingV2SubProducts.Topical:
      suggestedSubProduct = KingV2SubProducts.EncloTopical;
      break;
    case KingV2SubProducts.OralTrt:
      suggestedSubProduct = KingV2SubProducts.EncloTrt;
      break;
    case KingV2SubProducts.Injectable:
      suggestedSubProduct = KingV2SubProducts.InjectableHCG;
      break;
  }

  if (!suggestedSubProduct) {
    return null;
  }

  const subProduct = KingSubProductsConfig[suggestedSubProduct];
  const experimentVariation = useExperiment('testosterone_pricing', 'testosterone_pricing_variation_0');
  const firstInstallmentDiscount =
    useExperiment('testosterone_first_installment_discount', 'testosterone_first_installment_discount_variation_0') ===
    'testosterone_first_installment_discount_variation_1';

  useEffect(() => {
    trackEvent(`Viewed ${subProduct.addon_title} Page`, { protocol: 'king' });
  }, []);

  return (
    <>
      <div className="ml-10 sm:ml-5">
        <h3>Add {subProduct.addon_title}?</h3>
      </div>
      <Card>
        <Card.Body>
          <figure className="h-[180px] lg:h-[240px] mb-4">
            <img
              className="h-full w-auto"
              src={ImagesMapping[AvailableProducts.King][suggestedSubProduct]}
              alt="king v2"
            />
          </figure>
          <div className="text-center">
            <h4>{subProductNames[AvailableProducts.King][suggestedSubProduct]}</h4>
          </div>
          <p>{subProduct.addon_text}</p>
          <hr />
          {firstInstallmentDiscount ? (
            <div className="flex flex-col items-center text-center gap-y-1">
              <p className="font-inter font-bold text-[14px] leading-[20px] tracking-[0%] text-center align-middle mb-2">
                First month free*
              </p>
              <div className="flex items-center gap-x-1">
                <p className="text-center flex flex-row flex-center gap-x-1 mb-2">
                  ${Math.floor(kingMonthlyPriceExperiment(experimentVariation)[suggestedSubProduct][12] / 100)}/mo after
                  the first month
                </p>
              </div>
              <p className="text-[9px]">*First month pricing varies upon the plan you choose</p>
            </div>
          ) : (
            <p className="text-center flex flex-row flex-center gap-x-1 mb-2">
              Plans starting at
              <PriceFormatter
                period="mo"
                size="small"
                price={kingMonthlyPriceExperiment(experimentVariation)[suggestedSubProduct][12]}
              />
            </p>
          )}
          <div>
            <Button
              data-testid="own-lab-modal-close"
              onClick={() => {
                setFieldValue('selected_king_v2_product', suggestedSubProduct);
                trackEvent(`Completed ${subProduct.addon_title} Page`, {
                  protocol: 'king',
                  [`${subProduct.addon_title} Upsell Complete`]: 'Yes',
                });
                handleSubmit();
              }}
            >
              Add {subProduct.addon_title}
            </Button>
          </div>
        </Card.Body>
      </Card>
      <div className="mb-4 text-center">
        <button
          onClick={() => {
            trackEvent(`Completed ${subProduct.addon_title} Page`, {
              protocol: 'king',
              [`${subProduct.addon_title} Upsell Complete`]: 'No',
            });
            handleSubmit();
          }}
          className={cx({ 'bold': true, '!text-[#0C1442]': firstInstallmentDiscount })}
        >
          Not right now
        </button>
      </div>
    </>
  );
};

export default RecommendationFlowAddon;
